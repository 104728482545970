import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/Home";
import Login from "./routes/Login";
import ErrorPage from "./routes/ErrorPage";
import AuthenticatedRoute from "./routes/AuthenticatedRoute";
import { RouteObject } from "react-router";
import IssuePage from "./routes/IssuePage";

const wrapAuthenticatedRoute = (route: RouteObject) : RouteObject => {
    return {
        ...route,
        element: <AuthenticatedRoute redirectUrl={route.path}>{route.element}</AuthenticatedRoute>
    }
};

function App() {
    const router = createBrowserRouter([
        wrapAuthenticatedRoute({
            id: 'home',
            path: '/',
            element: <Home />,
            errorElement: <ErrorPage />,
        }),
        wrapAuthenticatedRoute({
            id: 'issue-view',
            path: "issues/:issueKey",
            element: <IssuePage />
        }),
        {
            id: 'login',
            path: "/login",
            element: <Login />,
        },
    ]);

  return (
      <>
          <RouterProvider router={router}></RouterProvider>
      </>
  );
}

export default App;
