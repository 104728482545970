import { createReducer } from "@reduxjs/toolkit";
import { searchIssues, updateIssues } from "./Action";
import Issue from "../../models/jira/Issue";
import { RefreshStatus } from "../../models/RefreshStatus";

export type IssueState = {
    items: Issue[],
    refreshStatus: RefreshStatus
}

export const issuesReducer = createReducer({items: [], refreshStatus: RefreshStatus.REFRESHING}, (builder) => {
    builder
        .addCase(updateIssues, (state: IssueState, { payload }) => {
            state.items = payload
        })
        .addCase(searchIssues.pending, (state: IssueState, action) => {
            state.refreshStatus = RefreshStatus.REFRESHING
        })
        .addCase(searchIssues.fulfilled, (state: IssueState, action) => {
            state.refreshStatus = RefreshStatus.REFRESHED
            // Add any fetched posts to the array
            state.items = action.payload
        })
        .addCase(searchIssues.rejected, (state: IssueState, action) => {
            state.refreshStatus = RefreshStatus.REFRESHED
            state.items = []
        })
})
