import { createSelector } from "@reduxjs/toolkit";
import State from "../State";
import { ProductIncrementState } from "./Reducer";
import ProductIncrement from "../../models/ProductIncrement";

export const getProductIncrements = createSelector(
    (state: State) => state.productIncrements,
    (pis: ProductIncrementState) => {
        return [...pis.productIncrements].sort((a, b) => a.start - b.start)
    }
)

export const getCurrentProductIncrement = createSelector(
    [getProductIncrements],
    (productIncrements: ProductIncrement[]) => {
        const currentDate = new Date().setHours(0,0,0,0)
        for(let i = 0; i < productIncrements.length; i++) {
            if (productIncrements[i].start <= currentDate && productIncrements[i].end >= currentDate) {
                return productIncrements[i]
            }
        }

        throw new Error('Cannot found current product increment.')
    }
)

export const getNextProductIncrement = createSelector(
    [getProductIncrements],
    (productIncrements: ProductIncrement[]) => {
        const currentDate = new Date().setHours(0,0,0,0)
        for(let i = 0; i < productIncrements.length; i++) {
            if (productIncrements[i].start > currentDate) {
                return productIncrements[i]
            }
        }

        throw new Error('Cannot found next product increment.')
    }
)
