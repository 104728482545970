import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from "@coreui/react";
import { searchIssues } from "../stores/issues/Action";
import { getTeams } from "../stores/teams/Selector";
import { RefreshStatus } from "../models/RefreshStatus";
import { CIcon } from "@coreui/icons-react";
import { cilSync } from "@coreui/icons";
import { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import State from "../stores/State";
import { getRefreshStatus } from "../stores/issues/Selector";
import { getCurrentProductIncrement, getNextProductIncrement } from "../stores/productIncrements/Selector";

let refreshTimeout: NodeJS.Timeout
let currentComponentKey: number = 0

const Refresher: React.FC<{}> = () => {
  const [refreshTime, setRefreshTime] = useState(15)

  const refreshTimeValues = [5, 15, 30, 60]
  currentComponentKey++
  const componentKey = currentComponentKey

  const teams = useSelector(getTeams)
  const refreshStatus = useSelector(getRefreshStatus)
  const currentProductIncrement = useSelector(getCurrentProductIncrement)
  const nextProductIncrement = useSelector(getNextProductIncrement)
  const dispatch = useDispatch<ThunkDispatch<State, any, UnknownAction>>()

  const refresh = async () => {
      if (refreshTimeout !== undefined) {
          clearTimeout(refreshTimeout)
      }

      if (componentKey != currentComponentKey) {
          return
      }

      dispatch(searchIssues(`issueType = Feature AND PI in('${currentProductIncrement.tag}', '${nextProductIncrement.tag}') AND Team in(${teams.map(team => team.teamId).join(',')}) ORDER BY status ASC, Rank ASC`))

      refreshTimeout = setTimeout(() => {
          refresh()
      }, refreshTime * 1000)
  }

  useEffect(() => {
      refresh()
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setRefreshTime(() => parseInt(e.target.value))
  }

  return (
      <>
          <span style={{marginTop: '6px', fontSize: '.8em'}}>Auto Refresh Timer :</span>
          <CDropdown style={{margin: '0px 10px'}}>
              <CDropdownToggle color="primary" size="sm" variant="outline">{refreshTime} sec</CDropdownToggle>
              <CDropdownMenu onChange={handleChange}>
                  {refreshTimeValues.map((time) => (
                      <CDropdownItem key={`refresh-timer-${time}`} selected={time == refreshTime}>{time} sec</CDropdownItem>
                  ))}
              </CDropdownMenu>
          </CDropdown>
          <CButton color="primary" size="sm" onClick={refresh} disabled={refreshStatus == RefreshStatus.REFRESHING}>
              Refresh {refreshStatus == RefreshStatus.REFRESHING ? (<span className="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></span>) : (<CIcon icon={cilSync}/>)}
          </CButton>
      </>
  );
}

export default Refresher;
