import { createReducer } from "@reduxjs/toolkit";
import { resetCredentials, updateCredentials } from "./Action";
import jiraClient from "../../services/JiraClient";

export type CredentialsState = {
    isLoggedIn: boolean
}

const initialValue: CredentialsState = {
    isLoggedIn: false
}

if (process.env.REACT_APP_JIRA_EMAIL && process.env.REACT_APP_JIRA_API_TOKEN) {
    initialValue.isLoggedIn = true
    jiraClient.setAuthorization(process.env.REACT_APP_JIRA_EMAIL, process.env.REACT_APP_JIRA_API_TOKEN)
}
//*/

export const credentialReducer = createReducer(initialValue, (builder) => {
    builder
        .addCase(updateCredentials, (state: CredentialsState, { payload }) => {
            state.isLoggedIn = true
            jiraClient.setAuthorization(payload.email, payload.apiToken)
        })
        .addCase(resetCredentials, (state: CredentialsState, { payload }) => {
            state.isLoggedIn = false
            jiraClient.resetAuthorization()
        })
})
