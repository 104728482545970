import { createSelector } from "@reduxjs/toolkit";
import Issue from "../../models/jira/Issue";
import { CustomField } from "../../models/jira/CustomField";
import { IssueState } from "./Reducer";
import State from "../State";
import { getTeams } from "../teams/Selector";
import { GroupedMap, IssuesByCommitment, IssuesByReadiness } from "../../models/Types";
import Utils from "../../utils/Utils";
import { getCurrentProductIncrement, getNextProductIncrement } from "../productIncrements/Selector";

const groupByReadiness = (issues: Issue[]) : IssuesByReadiness => {
    return Object.values(issues).reduce((acc, issue) => {
        if (Utils.isIssueReady(issue)) {
            acc.ready = [...acc.ready, issue]
        } else {
            acc.notReady = [...acc.notReady, issue]
        }

        return acc
    }, {notReady: [], ready: []} as IssuesByReadiness)
}

export const getIssues = createSelector(
    (state:State) => state.issues,
    (issues:IssueState) => issues.items
)

export const getIssuesCurrentPI = createSelector(
    [getIssues, getCurrentProductIncrement],
    (issues, currentProductIncrement) => issues.filter(issue => issue.fields[CustomField.PI].value === currentProductIncrement.tag)
)

export const getIssuesNextPI = createSelector(
    [getIssues, getNextProductIncrement],
    (issues, nextProductIncrement) => issues.filter(issue => issue.fields[CustomField.PI].value === nextProductIncrement.tag)
)

export const getIssuesByTeam = createSelector(
    [getIssuesNextPI],
    (issues:Issue[]) => issues.reduce((acc, issue) => {
        if (!issue.fields[CustomField.TEAM])
            return acc

        acc[issue.fields[CustomField.TEAM]?.id] = [...(acc[issue.fields[CustomField.TEAM]?.id] ?? []), issue]
        return acc
    }, {} as GroupedMap<Issue[]>)
)

export const getIssuesByTeamAndCommitment = createSelector(
    [getIssuesByTeam, getTeams],
    (issues:GroupedMap<Issue[]>, teams) => {
        const result: GroupedMap<IssuesByCommitment> = {}
        teams.forEach((team) => {
            const teamIssues = issues[team.teamId] ?? []

            const committed: Issue[] = []
            const notCommitted: Issue[] = []
            let cumulativeSize = 0

            teamIssues.forEach((issue) => {
                const size = Utils.normalizeSize(issue)
                cumulativeSize += size
                if(size > 0 && cumulativeSize <= team.velocity) {
                    committed.push(issue)
                } else {
                    notCommitted.push(issue)
                }
            })

            result[team.teamId] = {
                committed,
                notCommitted
            }
        })
        return result
    }
)

export const getIssuesInCurrentPIByTeam = createSelector(
    [getIssuesCurrentPI],
    (issues:Issue[]) => issues.reduce((acc, issue) => {
        if (!issue.fields[CustomField.TEAM])
            return acc

        acc[issue.fields[CustomField.TEAM]?.id] = [...(acc[issue.fields[CustomField.TEAM]?.id] ?? []), issue]
        return acc
    }, {} as GroupedMap<Issue[]>)
)

export const getIssuesByCommitment = createSelector([getIssuesByTeamAndCommitment], (statusIssueByTeam) => {
    return Object.values(statusIssueByTeam).reduce((acc, statusIssues) => {
        return {
            committed: [...acc.committed, ...statusIssues.committed],
            notCommitted: [...acc.notCommitted, ...statusIssues.notCommitted]
        };
    }, {committed: [], notCommitted: []} as IssuesByCommitment)
})

export const getIssuesByReadiness = createSelector([getIssuesNextPI], (issues) => {
    return groupByReadiness(issues)
})

export const getIssuesByAccount = createSelector(
    [getIssuesNextPI],
    (issues:Issue[]) => issues.reduce((acc, issue) => {
            if (!issue.fields[CustomField.ACCOUNT])
                return acc

            acc[issue.fields[CustomField.ACCOUNT]?.value] = [...(acc[issue.fields[CustomField.ACCOUNT]?.value] ?? []), issue]
            return acc
        }, {} as GroupedMap<Issue[]>)
)

export const getIssuesByAccountAndReadiness = createSelector(
    [getIssuesByAccount],
    (issuesByAccount:GroupedMap<Issue[]>) => {
        return Object.keys(issuesByAccount).reduce((result, key) => {
            result[key] = groupByReadiness(issuesByAccount[key])
            return result
        }, {} as GroupedMap<IssuesByReadiness>)
    }
)

export const getIssuesCount = createSelector(
    [getIssuesNextPI],
    (issues:Issue[]) => issues.length
)

export const getRefreshStatus = createSelector(
    (state:State) => state.issues,
    (issues:IssueState) => issues.refreshStatus
)
