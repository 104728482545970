import React from 'react';
import { GroupedMap, TypedEffort } from "../models/Types";
import { CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import Loader from "./Loader";

const AccountTable : React.FC<{efforts: GroupedMap<TypedEffort>, goodLabel: string}> = ({ efforts , goodLabel}) => {
  const totalSum = Object.values(efforts).reduce((total, effort) => total + effort.all, 0)
  const goodSum = Object.values(efforts).reduce((total, effort) => total + effort.good, 0)

  return (
        <>
            <Loader>
                <CTable bordered>
                    <CTableHead color="light">
                        <CTableRow>
                            <CTableHeaderCell scope="col">Account</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Σ Job Size ({goodLabel})</CTableHeaderCell>
                            <CTableHeaderCell scope="col">% ({goodLabel})</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        {Object.entries(efforts).sort(([, a], [, b]) => b.all - a.all).map(([account, sizes]) => (
                            <CTableRow key={account}>
                                <CTableDataCell>{account}</CTableDataCell>
                                <CTableDataCell>{sizes.all} ({sizes.good})</CTableDataCell>
                                <CTableDataCell>{`${totalSum == 0 ? 0 : Math.round((sizes.all / totalSum) * 100)}%`} ({`${goodSum == 0 ? 0 : Math.round((sizes.good / goodSum) * 100)}%`})</CTableDataCell>
                            </CTableRow>
                        ))}
                    </CTableBody>
                </CTable>
            </Loader>
      </>
  );
}

export default AccountTable;
