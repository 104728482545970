import React, { ReactNode, useState } from 'react';
import { CContainer, CFooter, CForm, CFormInput, CHeader, CHeaderBrand, CHeaderNav } from "@coreui/react";
import Refresher from "../components/Refresher";
import { generatePath, useNavigate } from "react-router";

const MainLayout : React.FC<{refresher?:boolean, children: ReactNode}> = ({ refresher, children }) => {
    const [searchIssue, setSearchIssue] = useState<string>()

    const navigate = useNavigate()

    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if(!searchIssue) {
            return
        }

        const result = searchIssue.match(/([a-zA-Z]+-[0-9]+)$/)

        if (!result) {
            return
        }

        navigate(generatePath('/issues/:issueKey', {issueKey: result[0]}))
    }

    return (
        <>
            <CHeader position='sticky' style={{marginBottom: '15px'}}>
                <CContainer fluid>
                    <CHeaderBrand onClick={() => navigate('/')} className="cil-mouse">PIP Helper</CHeaderBrand>
                    <CForm className="d-flex w-25" onSubmit={handleSubmit}>
                        <CFormInput type="search" className="me-2" placeholder="Search" onChange={(event) => setSearchIssue(event.target.value)} />
                    </CForm>
                    <CHeaderNav>
                        {refresher && (
                            <Refresher/>
                        )}
                    </CHeaderNav>
                </CContainer>
            </CHeader>
            <CContainer fluid>
                {children}
            </CContainer>
            <CFooter style={{marginTop: '15px', textAlign: 'right'}}>
                PIP Helper - created by Overkiz
            </CFooter>
        </>
    );
}

export default MainLayout;
