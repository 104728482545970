import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Issue from "../../models/jira/Issue";
import jiraClient from "../../services/JiraClient";
import { useNavigate } from "react-router";
import { resetCredentials } from "../credentials/Action";

export const updateIssues = createAction<Issue[]>("issues/update")

export const searchIssues = createAsyncThunk('issues/fetch', async (searchQuery: string, api) => {
    let issues : Issue[]

    try {
        issues = await jiraClient.searchIssues(searchQuery)
    } catch(e:any) {
        console.error(e)
        api.dispatch(resetCredentials())
        const navigate = useNavigate()
        navigate('/login')
        return []
    }

    return issues
})
