import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { CCard, CCardBody, CCardHeader, CNav, CNavItem, CNavLink, CTabContent, CTabPane } from "@coreui/react";
import { getTeams } from "../stores/teams/Selector";
import Backlog from "./Backlog";
import Loader from "./Loader";

const Backlogs: React.FC<{}> = () => {
  const teams = useSelector(getTeams)

  const [activeKey, setActiveKey] = useState(teams[0].teamId)

  return (
      <>
        <h1>Backlogs</h1>
        <CCard>
          <CCardHeader>
            <CNav variant="tabs" className="card-header-tabs">
              {teams.map((team) => (
                  <CNavItem key={`team-tab-${team.teamId}`}>
                    <CNavLink href="#!" active={activeKey === team.teamId} onClick={() => setActiveKey(team.teamId)}>
                      {team.name}
                    </CNavLink>
                  </CNavItem>
              ))}
            </CNav>
          </CCardHeader>
          <CCardBody>
            <CTabContent>
                <Loader>
                    {teams.map((team) => (
                        <CTabPane key={`team-panel-${team.teamId}`} role="tabpanel" aria-labelledby={team.teamId} visible={activeKey == team.teamId}>
                            <Backlog key={team.teamId} team={team}/>
                        </CTabPane>
                    ))}
                </Loader>
            </CTabContent>
          </CCardBody>
        </CCard>
      </>
  );
}

export default Backlogs;
