import React from 'react';
import Team from "../models/Team";
import { getIssuesByTeamAndCommitment } from "../stores/issues/Selector";
import Utils from '../utils/Utils';
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { CProgress, CProgressBar } from "@coreui/react";

const Workload: React.FC<{ team: Team }> = ({ team }) => {
  const issues = useSelector(createSelector([getIssuesByTeamAndCommitment], (statusIssueByTeam) => {
    return statusIssueByTeam[team.teamId] ?? []
  }))

  const committedCharge = [...issues.committed].reduce((acc, issue) => acc + Utils.normalizeSize(issue), 0)
  const readyCharge = [...issues.committed].filter((issue) => Utils.isIssueReady(issue)).reduce((acc, issue) => acc + Utils.normalizeSize(issue), 0)
  const value = committedCharge > 0 ? Math.round((readyCharge / committedCharge) * 100) : 0

  const color =
    value <= 25 ? 'danger' :
    value <= 50 ? 'warning' :
    value <= 75 ? 'info' :
    'success'

  return (
    <>
      <>{team.name}</>
      <CProgress height={20} color={color} value={value}>
        <CProgressBar className="overflow-visible text-dark px-1">{`${value}% (${readyCharge}/${committedCharge})`}</CProgressBar>
      </CProgress>
    </>
  );
}

export default Workload;
