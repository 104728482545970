import React from 'react';
import Utils from '../utils/Utils';
import Team from "../models/Team";
import AccountTable from "./AccountTable";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { getIssuesByTeamAndCommitment, getIssuesInCurrentPIByTeam } from "../stores/issues/Selector";
import IssueLine from "./IssueLine";
import IssueLineOfCurrentPI from "./IssueLineOfCurrentPI";
import { CButton, CCol, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import { updateTeam } from "../stores/teams/Action";

const Backlog: React.FC<{team: Team}> = ({ team }) => {

  const issuesInCurrentPI = useSelector(createSelector([getIssuesInCurrentPIByTeam], (statusIssueByTeam) => {
    return statusIssueByTeam[team.teamId] ?? []
  }))

  const issues = useSelector(createSelector([getIssuesByTeamAndCommitment], (statusIssueByTeam) => {
    return statusIssueByTeam[team.teamId] ?? []
  }))

  const committedCharge = [...issues.committed].reduce((acc, issue) => acc + Utils.normalizeSize(issue), 0)
  const notCommittedCharge = [...issues.notCommitted].reduce((acc, issue) => acc + Utils.normalizeSize(issue), 0)
  const dispatch = useDispatch()

  const updateTeamVelocity = (increment:number) => {
    dispatch(updateTeam({...team, velocity: team.velocity + increment}))
  }

  return (
    <React.Fragment>
      <h2 className={'backlog-title'}>Backlog for {team.name}</h2>
      <CRow>
        <CCol>
          <ul>
            <li>
              Team velocity:
              <CButton onClick={() => updateTeamVelocity(-.5)} size="sm" color="secondary" style={{margin: '0 10px'}}>-</CButton>
              <b>{team.velocity}</b>
              <CButton onClick={() => updateTeamVelocity(.5)} size="sm" color="secondary" style={{margin: '0 10px'}}>+</CButton>
            </li>
          </ul>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CTable bordered>
            <CTableHead color="light">
              <CTableRow>
                <CTableHeaderCell>Issue Key</CTableHeaderCell>
                <CTableHeaderCell>Ready</CTableHeaderCell>
                <CTableHeaderCell>Summary</CTableHeaderCell>
                <CTableHeaderCell>Status</CTableHeaderCell>
                <CTableHeaderCell>Job Size</CTableHeaderCell>
                <CTableHeaderCell>Account</CTableHeaderCell>
                <CTableHeaderCell style={{minWidth: '60px'}}></CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>

            {issuesInCurrentPI.map((issue) => (
                  <IssueLineOfCurrentPI key={issue.id} issue={issue}></IssueLineOfCurrentPI>
              ))}

            <>
              <CTableRow color="success">
                <CTableDataCell colSpan={7} style={{textAlign: 'center', fontStyle: 'italic'}}>&#9660; Committed {`(charge: ${committedCharge} / ${team.velocity})`}  &#9660;</CTableDataCell>
              </CTableRow>
            </>
              {issues.committed.map((issue) => (
                  <IssueLine key={issue.id} issue={issue}></IssueLine>
              ))}

              {issues.notCommitted.length > 0 && (
                  <>
                    <CTableRow color="danger">
                      <CTableDataCell colSpan={7} style={{textAlign: 'center', fontStyle: 'italic'}}>&#9660; Over Team Velocity {`(charge: ${notCommittedCharge})`}  &#9660;</CTableDataCell>
                    </CTableRow>
                  </>
              )}

              {issues.notCommitted.map((issue) => (
                  <IssueLine key={issue.id} issue={issue}></IssueLine>
              ))}
            </CTableBody>
          </CTable>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <AccountTable efforts={Utils.getEffortByAccount(issues.committed, issues.notCommitted)} goodLabel="Committed"></AccountTable>
        </CCol>
      </CRow>
    </React.Fragment>
  );
}

export default Backlog;
