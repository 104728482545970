import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { CCol, CRow } from "@coreui/react";
import { CIcon } from "@coreui/icons-react";
import { cilSad } from "@coreui/icons";

const IssueNotFound : React.FC = () => {
    return (
        <>
            <MainLayout>
                <CRow>
                    <CCol>
                        <CIcon icon={cilSad}></CIcon> Issue not found
                    </CCol>
                </CRow>
            </MainLayout>
        </>
    )
}

export default IssueNotFound
