import React from 'react';
import { getTeams } from "../stores/teams/Selector";
import Workload from "./Workload";
import { useSelector } from "react-redux";
import Loader from "./Loader";
import { CCard, CCardBody, CCardTitle } from "@coreui/react";

const Workloads: React.FC<{}> = ( ) => {
  const teams = useSelector(getTeams)

  return (
    <>
        <CCard>
            <CCardBody>
                <CCardTitle>Teams Ready Workloads</CCardTitle>
                <Loader>
                    {teams.map((team) => (
                        <Workload key={team.teamId} team={team}/>
                    ))}
                </Loader>
            </CCardBody>
        </CCard>
    </>
  );
}

export default Workloads;
