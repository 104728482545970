import React from 'react';
import { CWidgetStatsB } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import { useSelector } from "react-redux";
import { getCurrentProductIncrement, getNextProductIncrement } from "../stores/productIncrements/Selector";

const DaysRemaining: React.FC = () => {
  const currentProgramIncrement = useSelector(getCurrentProductIncrement)
  const nextProgramIncrement = useSelector(getNextProductIncrement)

  const totalDays = Math.ceil((currentProgramIncrement.end - currentProgramIncrement.start) / (1000 * 3600 * 24))

  const today = new Date();
  const daysPassed = Math.ceil((today.getTime() - currentProgramIncrement.start) / (1000 * 3600 * 24))
  const daysRemaining = Math.max(totalDays - daysPassed, 0)

  const progress = totalDays > 0 ? (daysPassed / totalDays) * 100 : 0;

  return (
    <CWidgetStatsB
      className="mb-4 bg-light"
      progress={{ color: 'dark', value: progress }}
      value={`${daysRemaining} days remaining`}
      title={`Before PI ${nextProgramIncrement.name}`}
      text={`PI ${currentProgramIncrement.name} : ${new Date(currentProgramIncrement.start).toLocaleDateString()} - ${new Date(currentProgramIncrement.end).toLocaleDateString()}`}
    />
  );
};

export default DaysRemaining;
