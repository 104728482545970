import React from 'react';
import DaysRemaining from "./DaysRemaining"
import Workloads from "./Workloads";
import { CCol, CRow } from "@coreui/react";
import Overall from "./Overall";

const Overalls : React.FC<{}> = () => {
  return (
      <>
          <CRow>
              <CCol lg={3}>
			  	<DaysRemaining/>
                <Workloads/>
              </CCol>
              <CCol lg={9}>
                <Overall/>
			  </CCol>
          </CRow>
      </>
  );
}

export default Overalls;
