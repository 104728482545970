import React, { ReactNode } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { isLoggedIn } from "../stores/credentials/Selector";

const AuthenticatedRoute: React.FC<{children: ReactNode, redirectUrl?:string}> = ({ children, redirectUrl }) => {
    const authenticated = useSelector(isLoggedIn);
    const location = useLocation();

    return authenticated ? (
        <>{children}</>
    ) : (
        <Navigate to={`/login?redirect=${encodeURIComponent(redirectUrl || location.pathname)}`}></Navigate>
    )
};

export default AuthenticatedRoute;
