import React from 'react';
import Overalls from "../components/Overalls";
import Backlogs from "../components/Backlogs";
import MainLayout from "../layouts/MainLayout";

const Home : React.FC = () => {
    return (
        <>
            <MainLayout refresher={true}>
                <Overalls/>
                <Backlogs/>
            </MainLayout>
        </>
    );
}

export default Home;
